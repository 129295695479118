import {Injectable} from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {CompanyStateService} from '../services/company-state.service';

@Injectable()
export class ActiveCompanyHeaderInterceptor implements HttpInterceptor {

  private companyActive: boolean;

  constructor(private companyStateService: CompanyStateService) {
    this.companyStateService.activeCompany.subscribe(
      next => this.companyActive = true
    );
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.companyActive) {
      return next.handle(request);
    }

    const result = new Observable<HttpEvent<unknown>>(subscriber => {

      this.companyStateService.activeCompany
        .pipe(take(1))
        .subscribe(activeCompany => {
          let nextObs: Observable<HttpEvent<any>>;

          if (!activeCompany) {
            nextObs = next.handle(request);

          } else {
            const headers = request.headers.append('X-NEFO-ACTIVE-COMPANY', activeCompany.id.toString());
            const modifiedRequest = request.clone({headers});
            nextObs = next.handle(modifiedRequest);
          }

          nextObs.subscribe(subscriber);
        });
    });

    return result;
  }
}
