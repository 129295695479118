import { KeycloakConfig } from "keycloak-js";

export const keycloakNonProdConfig: KeycloakConfig = {
  url: 'https://id.nils-eckert.de/auth/',
  realm: 'nils-eckert-nonprod',
  clientId: 'nefo'
};

export const keycloakProdConfig: KeycloakConfig = {
  url: 'https://id.nils-eckert.de/auth/',
  realm: 'nils-eckert-prod',
  clientId: 'nefo'
};
