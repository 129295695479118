<div class="page">
  <header class="navbar navbar-expand-md navbar-light">
    <div class="container-xl">
      <button class="navbar-toggler collapsed" type="button" (click)="isCollapsed = !isCollapsed">
        <span class="navbar-toggler-icon"></span>
      </button>
      <a routerLink class="navbar-brand d-none-navbar-horizontal pr-0 pr-md-5">
        <svg class="h-5 navbar-brand-image"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.28 19.38">
          <defs>
            <style>.cls-1 {
              fill: #575756;
            }</style>
          </defs>
          <g id="Ebene_1-2">
            <path class="cls-1"
                  d="m0,5.79c0-.27.24-.51.51-.51s.51.24.51.51v1.56c.99-1.86,3.99-2.49,5.58-2.07,1.65.42,2.64,1.86,2.64,3.72v9.72c0,.27-.24.51-.51.51s-.51-.24-.51-.51v-9.63c0-1.44-.72-2.52-1.89-2.82-1.32-.33-2.76.15-3.9.87-.84.54-1.41,1.41-1.41,2.4v9.18c0,.27-.24.51-.51.51s-.51-.24-.51-.51V5.79Z"/>
            <path class="cls-1"
                  d="m12.78,1.68c.42,0,.72.33.72.72s-.33.72-.72.72-.72-.33-.72-.72.33-.72.72-.72Zm-.51,4.11c0-.27.24-.51.51-.51s.51.24.51.51v12.93c0,.27-.24.51-.51.51s-.51-.24-.51-.51V5.79Z"/>
            <path class="cls-1"
                  d="m16.86,0c.27,0,.51.24.51.51v14.94c.03,1.56,1.08,2.4,2.31,2.76.3.09.42.3.42.54,0,.27-.27.54-.63.48-1.77-.36-3.12-1.77-3.12-3.78V.51c0-.27.24-.51.51-.51Z"/>
            <path class="cls-1"
                  d="m21.9,15.54c.27,0,.45.21.51.48.33,1.71,2.25,2.34,3.51,2.34,1.62,0,3.63-.9,3.63-2.94,0-2.22-1.77-2.58-3.69-3.09-1.56-.42-4.38-1.08-4.38-3.36,0-1.11.63-2.07,1.74-2.73,1.41-.84,3.24-1.35,4.71-.96,1.26.33,2.52,1.53,2.58,3.06,0,.27-.24.51-.51.51s-.48-.21-.51-.45c-.18-1.08-.84-1.89-1.83-2.13-1.32-.33-2.73.18-3.9.84-.87.48-1.26,1.23-1.26,1.89,0,1.32,2.16,2.04,3.45,2.34,1.77.42,4.62.87,4.62,4.08,0,2.55-2.19,3.96-4.65,3.96-2.16,0-4.05-1.05-4.5-3.09-.09-.33.06-.75.48-.75Z"/>
            <path class="cls-1"
                  d="m45.3,15.54c.27,0,.54.24.45.66-.42,2.07-2.4,3.18-4.59,3.18-2.46,0-4.59-1.44-4.59-3.96v-5.88c0-1.38.78-2.64,1.89-3.3,1.41-.84,3.24-1.35,4.71-.96,1.65.42,2.64,1.86,2.64,3.72v3.18c0,.27-.24.51-.51.51h-7.71v2.73c0,2.04,1.95,2.94,3.57,2.94,1.44,0,3.24-.69,3.57-2.34.09-.36.33-.48.57-.48Zm-7.71-3.87h7.2v-2.58c0-1.44-.72-2.52-1.89-2.82-1.32-.33-2.73.18-3.9.84-.87.48-1.41,1.41-1.41,2.4v2.16Z"/>
            <path class="cls-1"
                  d="m52.95,19.38c-2.46,0-4.59-1.44-4.59-3.96v-5.94c0-1.38.78-2.58,1.89-3.24,1.41-.84,3.24-1.35,4.71-.96,1.41.36,2.34,1.5,2.58,2.97.06.42-.27.6-.48.6-.3,0-.48-.21-.54-.51-.21-1.05-.87-1.83-1.83-2.07-1.32-.33-2.73.18-3.9.84-.87.48-1.41,1.41-1.41,2.4v5.91c0,2.04,1.95,2.94,3.57,2.94,1.47,0,3.3-.75,3.6-2.43.03-.21.27-.42.51-.42.36,0,.54.36.48.66-.33,2.07-2.37,3.21-4.59,3.21Z"/>
            <path class="cls-1"
                  d="m63.93,11.04c-.87.87-1.59,1.26-2.76,1.26v6.42c0,.27-.24.51-.51.51s-.51-.24-.51-.51V.51c0-.27.24-.51.51-.51s.51.24.51.51v10.77c1.08,0,1.59-.36,2.85-1.86l3.39-3.96c.18-.21.51-.27.75-.03.18.18.21.48.03.69l-3.54,4.11,4.41,8.22c.12.24.12.57-.15.72s-.6.06-.75-.21l-4.23-7.92Z"/>
            <path class="cls-1"
                  d="m79.5,15.54c.27,0,.54.24.45.66-.42,2.07-2.4,3.18-4.59,3.18-2.46,0-4.59-1.44-4.59-3.96v-5.88c0-1.38.78-2.64,1.89-3.3,1.41-.84,3.24-1.35,4.71-.96,1.65.42,2.64,1.86,2.64,3.72v3.18c0,.27-.24.51-.51.51h-7.71v2.73c0,2.04,1.95,2.94,3.57,2.94,1.44,0,3.24-.69,3.57-2.34.09-.36.33-.48.57-.48Zm-7.71-3.87h7.2v-2.58c0-1.44-.72-2.52-1.89-2.82-1.32-.33-2.73.18-3.9.84-.87.48-1.41,1.41-1.41,2.4v2.16Z"/>
            <path class="cls-1"
                  d="m82.62,5.79c0-.27.24-.51.51-.51s.51.24.51.51v1.65c.75-1.38,2.55-2.25,3.54-2.25.27,0,.54.18.54.51,0,.24-.18.45-.48.51-1.53.3-3.6,1.5-3.6,3.45v9.06c0,.27-.24.51-.51.51s-.51-.24-.51-.51V5.79Z"/>
            <path class="cls-1"
                  d="m89.19,5.28h1.11v-2.97c0-.27.24-.51.51-.51s.51.24.51.51v2.97h1.86c.27,0,.51.24.51.51s-.24.51-.51.51h-1.86v9c0,2.01.99,2.52,2.61,2.97.24.09.42.36.33.63-.06.27-.36.42-.63.36-2.37-.69-3.33-1.44-3.33-3.96V6.3h-1.11c-.27,0-.51-.24-.51-.51s.24-.51.51-.51Z"/>
          </g>
        </svg>
      </a>
      <div class="navbar-nav flex-row order-md-last">
        <div class="nav-item" ngbDropdown *ngIf="profile">
          <a tabindex="0" class="nav-link d-flex lh-1 text-reset p-0" ngbDropdownToggle role="button">
            <span class="avatar text-white" [ngStyle]="{backgroundImage: 'url(' + avatarUrl + ')'}"></span>
            <div class="d-none d-xl-block pl-2 pr-1">
              <div>{{ profile.firstName }} {{ profile.lastName }}</div>
              <div class="mt-1 small text-muted">{{ (companyStateService.activeCompany | async)?.text }}</div>
            </div>
          </a>
          <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
            <ng-container *ngIf="availableCompanies?.length > 1">
              <a ngbDropdownItem [routerLink] *ngFor="let c of availableCompanies" (click)="companyChanged(c)">{{ c.text }}</a>
              <div class="dropdown-divider"></div>
            </ng-container>
            <a ngbDropdownItem [routerLink] (click)="logout()">Logout</a>
          </div>
        </div>
      </div>
      <div class="collapse navbar-collapse pl-md-5" id="navbar-menu" [ngbCollapse]="isCollapsed">
        <div class="d-flex flex-column flex-md-row flex-fill align-items-stretch align-items-md-center">
          <ul class="navbar-nav" *ngIf="navItems">
            <li class="nav-item" *ngFor="let item of navItems">
              <a class="nav-link" [ngClass]="{active: isActive(item)}" [routerLink]="item.routerLink" (click)="handleNavigationClick(item)">
                <span class="nav-link-title">{{item.label}}</span>
              </a>
              <ul class="dropdown-menu dropdown-menu-columns dropdown-menu-columns-2 show d-md-none"
                  *ngIf="item.children && item.children.length > 0">
                <li *ngFor="let item of item.children">
                  <a class="dropdown-item" [routerLink]="item.routerLink">
                    {{ item.label }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
  <div class="navbar-expand-md" *ngIf="activeSubNav && activeSubNav.length > 0">
    <div class="collapse navbar-collapse" id="navbar-secondarymenu">
      <div class="navbar navbar-light navbar-subnav">
        <div class="container-xl">
          <ul class="navbar-nav">
            <li class="nav-item" *ngFor="let item of activeSubNav" [ngClass]="{ active: isActive(item) }">
              <a class="nav-link" [routerLink]="item.routerLink">
                <span class="nav-link-title">{{ item.label}}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="page-wrapper">
    <ng-container *ngIf="companySelected">
        <router-outlet></router-outlet>
    </ng-container>
  </div>
  <footer class="footer footer-transparent">
    <div class="container text-center">
            <span *ngIf="backendInfo.git">Backend Build: {{ backendInfo.git.commit.id }}
              ({{ backendInfo.git.commit.time }})</span>
      <span class="float-xs-right">&copy; Nils Eckert GmbH {{ year }}</span>
    </div>
  </footer>
</div>
