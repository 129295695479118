<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <h1 class="display-3 me-4">Abgemeldet</h1>
        <p class="my-4">Sie wurden wunschgemäß vom System abgemeldet!</p>
        <div class="mt-2">
          <button type="button" class="btn btn-primary" (click)="login()">Wieder einloggen</button>
        </div>
      </div>
    </div>
  </div>
</div>
