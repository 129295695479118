import {Injectable} from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private toastr: ToastrService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 403) {
          this.toastr.error('Unzureichende Rechte');
        } else {
          this.toastr.error('Es ist ein Fehler aufgetreten');
        }

        return throwError(err);
      })
    );
  }
}
