import {Component, OnInit} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {Router} from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private keycloakService: KeycloakService,
              private router: Router) {
  }

  async ngOnInit() {
    const loggedIn = await this.keycloakService.isLoggedIn();

    if (loggedIn) {
      this.router.navigate(['/']);
    }
  }

  login() {
    this.keycloakService.login({
      redirectUri: window.location.origin
    }).then();
  }
}
