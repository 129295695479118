import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  // tslint:disable-next-line
  selector: 'div[id="app"]',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
  notifcationOptions: any;

  constructor(private router: Router) {
    this.notifcationOptions = {
      timeOut: 5000,
      position: ['bottom', 'right'],
      theClass: 'notification'
    };
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
