import { BrowserModule } from '@angular/platform-browser';
import { ApplicationRef, DEFAULT_CURRENCY_CODE, DoBootstrap, LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
// Import containers
import { DefaultLayoutComponent } from './containers';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
// Import routing module
import { AppRoutingModule } from './app.routing';
// Import 3rd party components
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgbCollapseModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { keycloakNonProdConfig, keycloakProdConfig } from './keycloak.config';
import { httpInterceptorProviders } from './core/interceptors/http-interceptors';
import { AppAuthGuard } from './guards/app.authguard';
import { ActiveCompanyHeaderInterceptor } from './core/interceptors/active-company-header-interceptor.service';
import { provideNgxWebstorage, withLocalStorage, withNgxWebstorageConfig, withSessionStorage } from "ngx-webstorage";

const keycloakService = new KeycloakService();

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

registerLocaleData(localeDe);

@NgModule({
  declarations: [
        AppComponent,
        ...APP_CONTAINERS,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    SharedModule,
    KeycloakAngularModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      extendedTimeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    NgbCollapseModule,
    NgbDropdownModule,
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideNgxWebstorage(
      withLocalStorage(),
      withSessionStorage(),
      withNgxWebstorageConfig({
        separator: '.',
        caseSensitive: true,
        prefix: 'nefo'
      })
    ),
    {provide: LOCALE_ID, useValue: 'de-DE'},
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
    {provide: KeycloakService, useValue: keycloakService},
    httpInterceptorProviders,
    {provide: HTTP_INTERCEPTORS, useClass: ActiveCompanyHeaderInterceptor, multi: true},
    AppAuthGuard,
  ]
})
export class AppModule implements DoBootstrap {

  constructor() {
    registerLocaleData(localeDe);
  }

  async ngDoBootstrap(appRef: ApplicationRef) {
    console.log('bootstrapping');

    const activeConfig = (window.location.hostname === 'nefo.nils-eckert.de') ? keycloakProdConfig : keycloakNonProdConfig;

    console.log(`origin is ${window.location.hostname} - using config with realm: ${activeConfig.realm}`);

    try {
      await keycloakService.init({config: activeConfig, initOptions: {onLoad: 'check-sso'}, bearerPrefix: 'Bearer'});
      appRef.bootstrap(AppComponent);
    } catch (error) {
      console.error('Keycloak init failed', error);
    }
  }

}
