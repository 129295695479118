import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {ReferenceModel} from '../models/referencemodel';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {CompanyStateService} from './company-state.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private static readonly STORAGE_KEY = 'active-company-id';

  constructor(
    private httpClient: HttpClient,
    private companyStateService: CompanyStateService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
  ) {
    this.findAvailableCompanies().subscribe(
      result => {
        const companyFromStorage = this.sessionStorageService.retrieve(ProfileService.STORAGE_KEY)
          || this.localStorageService.retrieve(ProfileService.STORAGE_KEY);

        this.companyStateService.availableCompanies.next(result);

        if (result.length > 0) {
          const activeCompany = result.find(ref => ref.id === +companyFromStorage) || result[0];
          this.companyStateService.activeCompany.next(activeCompany);
        }
      }
    );
  }

  switchCompany(activeNumber: ReferenceModel) {
    this.companyStateService.activeCompany.next(activeNumber);
    this.localStorageService.store(ProfileService.STORAGE_KEY, activeNumber.id);
    this.sessionStorageService.store(ProfileService.STORAGE_KEY, activeNumber.id);
  }

  private findAvailableCompanies(): Observable<ReferenceModel[]> {
    return this.httpClient.get<ReferenceModel[]>('/api/v1/profile/companies');
  }
}
